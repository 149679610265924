$('.select2').select2
    allowClear: true

formValidationOptions = {
    rules: {
        email: {
            email: false,
            strictEmail: true,
        }
    },
    errorClass: 'help-block text-danger text-right',
    highlight: (element, errorClass, validClass) ->
        $(element).parent().addClass('has-feedback').removeClass('has-success').addClass('has-error')
        $(element).next().addClass(errorClass)
    unhighlight: (element, errorClass, validClass) ->
        $(element).parent().addClass('has-feedback').removeClass('has-error').addClass('has-success')
        $(element).next().removeClass(errorClass)
}

$('.form-category').validate(formValidationOptions)
$('.form-item').validate(formValidationOptions)
$('.form-banner').validate(formValidationOptions)
$('.form-setting').validate(formValidationOptions)
$('.form-partner').validate(formValidationOptions)
$('.form-news-category').validate(formValidationOptions)
$('.form-news').validate(formValidationOptions)
$('.form-branch').validate(formValidationOptions)

$('.date-picker').datepicker({
    orientation: "top auto",
    autoclose: true,
    format: 'dd/mm/yyyy'
})

$('.summernote').summernote({
    height: 200,
    toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['table', 'link']],
        ['view', ['fullscreen', 'codeview']],
        ['color', ['color']],
    ]
});

$('.summernote-short').summernote({
    height: 50,
    toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['insert', ['link']],
        ['view', ['fullscreen', 'codeview']],
        ['color', ['color']],
    ]
});

$('.delete-image').on 'click', (e) ->
    e.preventDefault()
    confirmed = confirm 'Are you sure?'

    if confirmed
        that = $(this)
        $.ajax
            url: that.attr('href')
            type: 'delete'
            data: _token: that.data('token')
            success: ->
                that.parents('li').fadeOut 250, ->
                    $(this).remove()

# $('.form-news .btn-primary').click ->
#     fileUpload = $('.form-news #images')
#     if parseInt(fileUpload.get(0).files.length) > 2
#         alert 'You can only upload a maximum of 2 files'
#         return false
#     return

$('.form-item .btn-primary').click ->
    fileUpload = $('.form-item #images')
    if parseInt(fileUpload.get(0).files.length) > 1
        alert 'You can only upload a maximum of 1 files'
        return false
    return
